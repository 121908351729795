<template>
  <div class="px-5" style="max-height: 100vh; overflow: auto">
    <h6 class="my-10">{{ $t('TERMS.DISPLAY.TITLE') }}</h6>
    <span
      v-if="projectBrandObject[12].value == 2"
      v-html="projectBrandObject[12].decodedOptions.message"
    >
    </span>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ProjectTerms",
  
  computed: {
    ...mapGetters([
      "projectBrandObject"
    ])
  }
}
</script>